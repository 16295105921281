import Link from "gatsby-link";
import React from "react";
import Helmet from "react-helmet";
import "../styles/application.scss";

const Header = ({ activeLocation }) => {
  let headerImg = "home";

  switch (activeLocation) {
    case "/aktuelles":
      headerImg = "aktuelles";
      break;

    case "/anfahrt":
      headerImg = "anfahrt";
      break;

    case "/impressum":
      headerImg = "impressum";
      break;

    case "/leistungen":
      headerImg = "leistungen";
      break;

    case "/termine":
      headerImg = "termine";
      break;
  }

  return (
    <header className="page-header">
      <img
        src={`/img/header/${headerImg}.jpg`}
        className="page-header__bg"
        width="1080"
        height="350"
      />
      <div className="page-header__txt">
        <img className="page-nav__logo" src="/img/logo.svg" alt="Dr. Esswein" />
      </div>
    </header>
  );
};

const Sidebar = () => (
  <div className="sidebar">
    <div className="sidebar__fields">
      <div className="sidebar__fieldTitle">Facharzt für Orthopädie</div>
      <ul>
        <li>Chirotherapie</li>
        <li>Sportmedizin</li>
        <li>Akupunktur</li>
      </ul>
    </div>

    <img
      className="sidebar__potrait"
      src="/img/dr-esswein.jpg"
      alt="Dr. Esswein"
    />

    <div className="consultHours">
      <table>
        <tbody>
          <tr>
            <td>Montag</td>
            <td>8.00</td>
            <td> - </td>
            <td>12.00</td>
          </tr>
          <tr>
            <td></td>
            <td>14.00</td>
            <td> - </td>
            <td>16.00</td>
          </tr>
          <tr className="consultHours__day">
            <td>Dienstag</td>
            <td>8.00</td>
            <td> - </td>
            <td>12.00</td>
          </tr>
          <tr>
            <td></td>
            <td>15.00</td>
            <td> - </td>
            <td>18.00</td>
          </tr>
          <tr className="consultHours__day">
            <td>Mittwoch</td>
            <td>8.00</td>
            <td> - </td>
            <td>12.00</td>
          </tr>
          <tr className="consultHours__day">
            <td>Donnerstag</td>
            <td>8.00</td>
            <td> - </td>
            <td>12.00</td>
          </tr>
          <tr>
            <td></td>
            <td>15.00</td>
            <td> - </td>
            <td>18.00</td>
          </tr>
          <tr className="consultHours__day">
            <td>Freitag</td>
            <td>8.00</td>
            <td> - </td>
            <td>12.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

const Nav = () => (
  <nav className="page-nav">
    <Link
      className="page-nav__link"
      exact={true}
      activeClassName="page-nav__link--active"
      to="/"
    >
      Home
    </Link>
    <Link
      className="page-nav__link"
      activeClassName="page-nav__link--active"
      to="/leistungen"
    >
      Leistungen
    </Link>
    <Link
      className="page-nav__link"
      activeClassName="page-nav__link--active"
      to="/termine"
    >
      Termine
    </Link>
    <Link
      className="page-nav__link"
      activeClassName="page-nav__link--active"
      to="/anfahrt"
    >
      Anfahrt
    </Link>
    {/* <Link className="page-nav__link" activeClassName="page-nav__link--active" to="/aktuelles">Aktuelles</Link> */}
  </nav>
);

const Footer = () => (
  <footer className="page-footer">
    <span>Praxis Dr. med. Wolf-Bernd Esswein ·</span>
    <span> Steinhäuserstr. 2 ·</span>
    <span> 76135 Karlsruhe ·</span>
    <span> Tel. (0721) 8 46 46 ·</span>
    <div className="page-footer__links">
      <Link className="page-footer__link" to="/datenschutz">
        Datenschutz
      </Link>{" "}
      |
      <Link className="page-footer__link" to="/impressum">
        Impressum
      </Link>
    </div>
  </footer>
);

export const Layout = (props) => {
  return (
    <div className="page-wrap">
      <Helmet
        title="Dr. Esswein"
        meta={[
          {
            name: "description",
            content:
              "Informationen &#252;ber die Erreichbarkeit und Leistungen der Orthop&#228;dischen Praxis Dr. Esswein",
          },
          {
            name: "keywords",
            content: "Akupunktur, Orthop&#228;die, Chirotherapie, Sportmedizin",
          },
        ]}
      />

      <Header activeLocation={props.location.pathname} />

      <div className="page-content">
        <Sidebar />
        <div className="content">
          <Nav />
          <div className="page-element">{props.children}</div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Layout;
